export const FIRST_ACTION = 'FIRST_ACTION';
export const SECOND_ACTION = 'SECOND_ACTION';
export const CLOSE_BURNOUT_RES_MODAL = 'CLOSE_BURNOUT_RES_MODAL';
export const SET_BURNOUT_MODAL_DATA = 'SET_BURNOUT_MODAL_DATA';
export const OPEN_WELLBEING_MODAL = 'OPEN_WELLBEING_MODAL';
export const CLOSE_WELLBEING_MODAL = 'CLOSE_WELLBEING_MODAL';
export const UPDATE_HEALTH_METRICS_SUBMISSION = 'UPDATE_HEALTH_METRICS_SUBMISSION';
export const UPDATE_BURNOUT_SUBMISSION = 'UPDATE_BURNOUT_SUBMISSION';
export const SET_HEALTH_METRICS_CHART_DATA = 'SET_HEALTH_METRICS_CHART_DATA';
export const SET_BURNOUT_CHART_DATA = 'SET_BURNOUT_CHART_DATA';
export const SET_TODAY_BURNOUT = 'SET_TODAY_BURNOUT';
export const DISPLAY_PRICING_MODAL = 'DISPLAY_PRICING_MODAL';
export const IS_FIRST_LOGIN = 'IS_FIRST_LOGIN';
