import { createContext, useContext, Dispatch } from 'react';

export interface IHealthChartData {
	id: string;
	user_id: string;
	stress_level: number;
	sleep_quality: number;
	energy_level: number;
	mood_level: number;
	created_at: string;
	updated_at: string;
}

export interface IBurnoutData {
	burnout_score: number;
	created_at: string;
	scale: number;
	image_url: string;
}

export interface RootState {
	firstVal: string;
	openBurnoutResModal: boolean;
	openWellbeingModal: boolean;
	healthMetriceSubmission: boolean;
	burnoutSubmission: boolean;
	burnoutModalContent: {
		burnout_definition: string;
		burnout_level: string;
		burnout_score: number;
		image_url: string;
	};
	healthMetricsChartContent: IHealthChartData[] | [];
	burnoutChartContent: IBurnoutData[] | [];
	todayBurnout: {
		burnout_title: string;
		image_url: string;
		burnout_level: string;
	};
	displayPricingModal: boolean;
	is_first_login: boolean;
}
export type IAppContext = {
	state: RootState;
	dispatch: Dispatch<any>; // Replace ActionType with your actual action type
};

export const AppContext = createContext<IAppContext | undefined>(undefined);

export const useAppContext = () => useContext(AppContext);

const savedState = localStorage.getItem('app-state');

export const initialState = (savedState && JSON.parse(savedState)) || {
	firstVal: 'first value',
	openBurnoutResModal: false,
	openWellbeingModal: false,
	healthMetriceSubmission: false,
	burnoutSubmission: false,

	burnoutModalContent: {
		burnout_definition: '',
		burnout_level: '',
		burnout_score: 0,
		image_url: '',
	},
	healthMetricsChartContent: [],
	burnoutChartContent: [],
	todayBurnout: {
		burnout_title: '',
		image_url: '',
		burnout_level: '',
	},
	displayPricingModal: true,
	is_first_login: false,
};
