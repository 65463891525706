import UserImage7 from '../../assets/img/wanna/wanna7.png';
import UserImage7Webp from '../../assets/img/wanna/wanna7.webp';

export interface IUserProps {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	created_at: string;
	updated_at?: string;
	authToken: string;
	avatar: string;
	src: string;
	srcSet: string;
	healthMetriceSubmission?: boolean | false;
	burnoutSubmission?: boolean | false;
	is_subscription_active: boolean;
}

const john: IUserProps = {
	id: '1',
	first_name: 'john',
	last_name: 'John',
	email: 'Doe',
	authToken: 'CEO, Founder',
	created_at: 'john@omtanke.studio',
	updated_at: 'kasjdfl',
	src: UserImage7,
	srcSet: UserImage7Webp,
	is_subscription_active: false,
	avatar: '',
};

const grace: IUserProps = {
	id: '1',
	first_name: 'john',
	last_name: 'John',
	email: 'Doe',
	authToken: 'CEO, Founder',
	created_at: 'john@omtanke.studio',
	updated_at: 'kasjdfl',
	src: UserImage7,
	srcSet: UserImage7Webp,
	is_subscription_active: false,
	avatar: '',
};

const jane: IUserProps = {
	id: '1',
	first_name: 'john',
	last_name: 'John',
	email: 'Doe',
	authToken: 'CEO, Founder',
	created_at: 'john@omtanke.studio',
	updated_at: 'kasjdfl',
	src: UserImage7,
	srcSet: UserImage7Webp,
	is_subscription_active: false,
	avatar: '',
};

const ryan: IUserProps = {
	id: '1',
	first_name: 'john',
	last_name: 'John',
	email: 'Doe',
	authToken: 'CEO, Founder',
	created_at: 'john@omtanke.studio',
	updated_at: 'kasjdfl',
	src: UserImage7,
	srcSet: UserImage7Webp,
	is_subscription_active: false,
	avatar: '',
};

const ella: IUserProps = {
	id: '1',
	first_name: 'john',
	last_name: 'John',
	email: 'Doe',
	authToken: 'CEO, Founder',
	created_at: 'john@omtanke.studio',
	updated_at: 'kasjdfl',
	src: UserImage7,
	srcSet: UserImage7Webp,
	is_subscription_active: false,
	avatar: '',
};

const chloe: IUserProps = {
	id: '1',
	first_name: 'john',
	last_name: 'John',
	email: 'Doe',
	authToken: 'CEO, Founder',
	created_at: 'john@omtanke.studio',
	updated_at: 'kasjdfl',
	src: UserImage7,
	srcSet: UserImage7Webp,
	is_subscription_active: false,
	avatar: '',
};
const sam: IUserProps = {
	id: '1',
	first_name: 'john',
	last_name: 'John',
	email: 'Doe',
	authToken: 'CEO, Founder',
	created_at: 'john@omtanke.studio',
	updated_at: 'kasjdfl',
	src: UserImage7,
	srcSet: UserImage7Webp,
	is_subscription_active: false,
	avatar: '',
};

const USERS: { [key: string]: IUserProps } = {
	JOHN: john,
	GRACE: grace,
	JANE: jane,
	RYAN: ryan,
	ELLA: ella,
	CHLOE: chloe,
	SAM: sam,
};

export function getUserDataWithUsername(username: string): IUserProps {
	// @ts-ignore
	return USERS[Object.keys(USERS).filter((f) => USERS[f].username.toString() === username)];
}

export function getUserDataWithId(id?: string): IUserProps {
	// @ts-ignore
	return USERS[Object.keys(USERS).filter((f) => USERS[f].id.toString() === id.toString())];
}

export const countries = [
	{ value: 'af', text: 'Afghanistan' },
	{ value: 'dz', text: 'Algeria' },
	{ value: 'ao', text: 'Angola' },
	{ value: 'ar', text: 'Argentina' },
	{ value: 'au', text: 'Australia' },
	{ value: 'bd', text: 'Bangladesh' },
	{ value: 'br', text: 'Brazil' },
	{ value: 'ca', text: 'Canada' },
	{ value: 'cn', text: 'China' },
	{ value: 'co', text: 'Colombia' },
	{ value: 'cd', text: 'DR Congo' },
	{ value: 'eg', text: 'Egypt' },
	{ value: 'et', text: 'Ethiopia' },
	{ value: 'fr', text: 'France' },
	{ value: 'de', text: 'Germany' },
	{ value: 'gh', text: 'Ghana' },
	{ value: 'in', text: 'India' },
	{ value: 'id', text: 'Indonesia' },
	{ value: 'ir', text: 'Iran' },
	{ value: 'iq', text: 'Iraq' },
	{ value: 'it', text: 'Italy' },
	{ value: 'jp', text: 'Japan' },
	{ value: 'ke', text: 'Kenya' },
	{ value: 'mg', text: 'Madagascar' },
	{ value: 'my', text: 'Malaysia' },
	{ value: 'mx', text: 'Mexico' },
	{ value: 'ma', text: 'Morocco' },
	{ value: 'mz', text: 'Mozambique' },
	{ value: 'mm', text: 'Myanmar' },
	{ value: 'np', text: 'Nepal' },
	{ value: 'ng', text: 'Nigeria' },
	{ value: 'pk', text: 'Pakistan' },
	{ value: 'pe', text: 'Peru' },
	{ value: 'ph', text: 'Philippines' },
	{ value: 'pl', text: 'Poland' },
	{ value: 'ru', text: 'Russia' },
	{ value: 'sa', text: 'Saudi Arabia' },
	{ value: 'za', text: 'South Africa' },
	{ value: 'kr', text: 'South Korea' },
	{ value: 'es', text: 'Spain' },
	{ value: 'sd', text: 'Sudan' },
	{ value: 'tz', text: 'Tanzania' },
	{ value: 'th', text: 'Thailand' },
	{ value: 'tr', text: 'Turkey' },
	{ value: 'ug', text: 'Uganda' },
	{ value: 'ua', text: 'Ukraine' },
	{ value: 'gb', text: 'United Kingdom' },
	{ value: 'us', text: 'United States' },
	{ value: 'uz', text: 'Uzbekistan' },
	{ value: 'vn', text: 'Vietnam' },
	{ value: 'ye', text: 'Yemen' },
];

export default USERS;
