import dayjs from 'dayjs';
import { IBurnoutData, IHealthChartData } from '../contexts/AppContext/AppContext';

export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const isValidToken = (token: string) => {
	if (!token) return false;

	if (token.split('.').length === 3 && token.length > 100) {
		return true;
	}

	return false;
};

export function extractDataByDays(day: number, dataArray: IHealthChartData[]) {
	const currentDate = dayjs();
	const data = [];

	for (let i = 0; i <= day; i++) {
		const date = currentDate.subtract(i, 'day');
		const formattedDate = date.format('YYYY-MM-DD');
		const dataForDate = dataArray.find((item) => {
			const itemDate = dayjs(item.created_at).format('YYYY-MM-DD');
			return itemDate === formattedDate;
		});
		if (dataForDate) {
			data.push(dataForDate);
		} else {
			data.push({
				created_at: formattedDate,
				stress_level: 0,
				sleep_quality: 0,
				energy_level: 0,
				mood_level: 0,
			});
		}
	}

	const returnArr = [
		{ name: 'Stress Level', data: data.reverse().map((r) => r.stress_level) },
		{ name: 'Sleep quality', data: data.map((r) => r.sleep_quality) },
		{ name: 'Energy Level', data: data.map((r) => r.energy_level) },
		{ name: 'Mood', data: data.map((r) => r.mood_level) },
	];

	return returnArr;
}

export function extractBurnoutDataByDays(day: number, dataArray: IBurnoutData[]) {
	const currentDate = dayjs();
	const data: any = [];

	for (let i = 0; i <= day; i++) {
		const date = currentDate.subtract(i, 'day');
		const formattedDate = date.format('YYYY-MM-DD');
		const dataForDate = dataArray.find((item) => {
			const itemDate = dayjs(item.created_at).format('YYYY-MM-DD');
			return itemDate === formattedDate;
		});

		if (dataForDate) {
			data.push({
				created_at: dataForDate.created_at,
				scale: dataForDate.scale,
				image_url: dataForDate.image_url,
			});
		} else {
			data.push({
				created_at: '',
				scale: 0,
			});
		}
	}

	const returnArr = [
		{
			name: 'Burnout Score',
			data: data.reverse().map((r: IBurnoutData) => r.scale),
			image_url: data.map((r: IBurnoutData) => r.image_url),
		},
	];

	return returnArr;
}

export function getDaysDifferenceFromDate(inputDate: string) {
	const currentDate = dayjs();
	const inputDateObj = dayjs(inputDate);

	const daysDifference = Math.abs(inputDateObj.diff(currentDate, 'day'));

	return daysDifference;
}

export const extrachImgName = (url: string) => {
	if (!url.length || !url.includes('https:')) return '';

	return url.split('/').pop()?.replace('+', ' ').replace('.png', '');
};

export const extractFirstErr = (arg: string[] | string) => {
	if (typeof arg === 'string') {
		return arg;
	}

	return arg[0];
};
