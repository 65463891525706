import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Content from '../Content/Content';
import WrapperOverlay from './WrapperOverlay';
import HeaderRoutes from '../Header/HeaderRoutes';
import FooterRoutes from '../Footer/FooterRoutes';
import ThemeContext from '../../contexts/themeContext';

interface IWrapperContainerProps {
	children: ReactNode;
	className?: string;
}
export const WrapperContainer: FC<IWrapperContainerProps> = ({ children, className, ...props }) => {
	const { rightPanel } = useContext(ThemeContext);
	return (
		<div
			className={classNames(
				'wrapper',
				{ 'wrapper-right-panel-active': rightPanel },
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
	);
};
WrapperContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
WrapperContainer.defaultProps = {
	className: undefined,
};

const Wrapper = () => {
	const [showHeaderFooter, setShowHeaderFooter] = useState<boolean>(true);
	const location = useLocation();

	useEffect(() => {
		if (
			location.pathname.includes('/auth-pages/forgot-password') ||
			location.pathname.includes('/auth-pages/reset-password')
		) {
			setShowHeaderFooter(false);
		}
		if (location.pathname === '/') {
			setShowHeaderFooter(true);
		}
	}, [location.pathname]);

	return (
		<>
			<WrapperContainer>
				{showHeaderFooter && <HeaderRoutes />}
				<Content />
				{showHeaderFooter && <FooterRoutes />}
			</WrapperContainer>
			<WrapperOverlay />
		</>
	);
};

export default Wrapper;
