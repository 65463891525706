import React, { useContext } from 'react';
// import classNames from 'classnames';
// import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { userDashboardSidebarMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
// import Card, { CardBody } from '../../../components/bootstrap/Card';
// import Hand from '../../../assets/img/hand.png';
// import HandWebp from '../../../assets/img/hand.webp';
// import Icon from '../../../components/icon/Icon';
// import Button from '../../../components/bootstrap/Button';
// import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	// const [doc, setDoc] = useState(
	// 	localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	// );

	// const { t } = useTranslation(['translation', 'menu']);

	// const { darkModeStatus } = useDarkMode();

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={userDashboardSidebarMenu} id='aside-menu' />
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
