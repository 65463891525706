import React from 'react';
import PropTypes from 'prop-types';
import logo from '../assets/logos/logo.png';
import logoWhite from '../assets/logos/logo-white.png';

interface LogoProps {
	style: React.CSSProperties; // Define the prop type
	isWhite?: boolean;
}

const CompanyLogo = ({ style, isWhite }: LogoProps) => {
	return (
		<div>
			<img src={isWhite ? logoWhite : logo} alt='Logo' style={style} />
		</div>
	);
};

CompanyLogo.defaultProps = {
	isWhite: false,
};

CompanyLogo.propTypes = {
	isWhite: PropTypes.bool, // Define the PropTypes
};

export default CompanyLogo;
