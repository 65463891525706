import {
	FIRST_ACTION,
	SET_BURNOUT_MODAL_DATA,
	CLOSE_BURNOUT_RES_MODAL,
	OPEN_WELLBEING_MODAL,
	CLOSE_WELLBEING_MODAL,
	UPDATE_BURNOUT_SUBMISSION,
	UPDATE_HEALTH_METRICS_SUBMISSION,
	SET_BURNOUT_CHART_DATA,
	SET_HEALTH_METRICS_CHART_DATA,
	SET_TODAY_BURNOUT,
	DISPLAY_PRICING_MODAL,
	IS_FIRST_LOGIN,
} from './Actions';
import { RootState, initialState } from './AppContext';

// eslint-disable-next-line @typescript-eslint/default-param-last
const AppReducer = (state: RootState = initialState, action: any) => {
	// eslint-disable-next-line @typescript-eslint/default-param-last
	switch (action.type) {
		case FIRST_ACTION:
			return {
				...state,
				...action.payload,
			};

		case CLOSE_BURNOUT_RES_MODAL: {
			const updatedState = { ...state, openBurnoutResModal: false };
			return storedState(updatedState);
		}
		case SET_BURNOUT_MODAL_DATA: {
			const updatedState = {
				...state,
				openBurnoutResModal: true,
				burnoutModalContent: action.payload,
			};
			return storedState(updatedState);
		}
		case OPEN_WELLBEING_MODAL: {
			const updatedState = { ...state, openWellbeingModal: true };
			return storedState(updatedState);
		}
		case CLOSE_WELLBEING_MODAL: {
			const updatedState = { ...state, openWellbeingModal: false };
			return storedState(updatedState);
		}
		case UPDATE_HEALTH_METRICS_SUBMISSION: {
			return { ...state, healthMetriceSubmission: action.payload };
		}
		case UPDATE_BURNOUT_SUBMISSION: {
			const updatedState = { ...state, burnoutSubmission: action.payload };
			return storedState(updatedState);
		}
		case SET_HEALTH_METRICS_CHART_DATA: {
			const updatedState = { ...state, healthMetricsChartContent: action.payload };
			return storedState(updatedState);
		}
		case SET_BURNOUT_CHART_DATA: {
			const updatedState = { ...state, burnoutChartContent: action.payload };
			return storedState(updatedState);
		}
		case SET_TODAY_BURNOUT: {
			const updatedState = { ...state, todayBurnout: action.payload };
			return storedState(updatedState);
		}
		case DISPLAY_PRICING_MODAL: {
			const updatedState = { ...state, displayPricingModal: action.payload };
			return storedState(updatedState);
		}
		case IS_FIRST_LOGIN: {
			const updatedState = { ...state, is_first_login: action.payload };
			return storedState(updatedState);
		}

		default:
			return state;
	}
};

export default AppReducer;

function storedState(state: any) {
	localStorage.setItem('app-state', JSON.stringify(state));
	return state;
}
