import React, { useContext } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Avatar from '../../../components/Avatar';
// import UserImage2Webp from '../../../assets/img/wanna/wanna1.webp';
// import UserImage2 from '../../../assets/img/wanna/wanna1.png';
import CommonHeaderRight from './CommonHeaderRight';
import AuthContext from '../../../contexts/authContext';

const ProfilePageHeader = () => {
	const { userData } = useContext(AuthContext);

	return (
		<Header>
			<HeaderLeft>
				<div className='col d-flex align-items-center'>
					<div className='me-3'>
						<Avatar
							// @ts-ignore
							srcSet={userData?.avatar || process.env.REACT_APP_DEFAULT_AVATAR}
							// @ts-ignore
							src={userData?.avatar || process.env.REACT_APP_DEFAULT_AVATAR}
							size={48}
							color='primary'
						/>
					</div>
					<div>
						<div className='fw-bold fs-6 mb-0'>
							{userData?.first_name} {userData?.last_name}
						</div>
						{/* <div className='text-muted'>
							<small>Founder</small>
						</div> */}
					</div>
				</div>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default ProfilePageHeader;
