import React, { useMemo } from 'react';
import { AppContext, IAppContext, initialState } from './AppContext';
import AppReducer from './AppReducer';

interface Props {
	children: React.ReactNode;
}

const AppProvider = ({ children }: Props) => {
	const [state, dispatch] = React.useReducer(AppReducer, initialState);

	const contextValue: IAppContext = useMemo(() => {
		return { state, dispatch };
	}, [state, dispatch]);

	// Memoize the value passed to AppContext.Provider
	// @ts-ignore
	return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppProvider;
